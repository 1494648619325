:root {
  --teal-color: #00c59f;
  --pink-color: #bd66b1;
  --yellow-color: #d3ac61;
  --dark-gray-color: #171717;
  --light-gray-color: #363636;
  --x-light-gray-color: #dbdbdb;
  --color-true: #4b8d3b;
  --color-false: #ff6958;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

a {
  color: unset;
}

.app {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 150px auto;
}

header {
  background-color: var(--dark-gray-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.optionsHeader {
  position: absolute;
  bottom: -1rem;
  color: var(--pink-color);
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  text-transform: uppercase;
  left: 1rem;
}

.descriptionsHeader {
  position: absolute;
  bottom: -1rem;
  color: var(--pink-color);
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  text-transform: uppercase;
  right: 1rem;
}
.title-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtext {
  font-size: 1.5rem;
  color: var(--yellow-color);
  font-weight: 300;
}

main {
  border-top: 1px solid var(--light-gray-color);
  height: auto;
  background-color: var(--dark-gray-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10%;
  color: gray;
  font-weight: lighter !important;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

h2 {
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
  font-size: 2rem;
}

span {
  font-weight: bolder;
}
h1 {
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
  color: var(--teal-color);
}

.left {
  border-right: 1px solid var(--light-gray-color);
  position: relative;
}

.right {
  padding: 2rem;
}

.logo {
  color: var(--teal-color);
  font-family: "Courier New", Courier, monospace;
  font-size: 3rem;
  line-height: 1rem;
}

.text {
  font-family: "Courier New", Courier, monospace;
  font-size: 2rem;
  font-weight: 100;
}

.column-header {
  color: black;
  text-transform: uppercase;
  font-size: 2rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.ml-2 {
  margin-left: 1rem;
}

.links {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  color: var(--yellow-color);
}

.search {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  padding: 1rem;
  color: var(--yellow-color);
}

input[type="search"] {
  background: transparent;
  padding: 1px 1px 1px 5px;
  position: relative;
  top: 0;
  left: 0;
  width: 178px;
  color: var(--yellow-color);
  height: 32px;
  font-size: 1rem;
  outline: none;
  border: 1px solid var(--yellow-color);
  border-radius: 6px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 200;
}
input[type="search"]:focus {
  background: transparent;
  padding: 1px 1px 1px 5px;
  width: 300px;
  height: 32px;
  border: 1px solid var(--yellow-color);
  top: 0;
  right: 100%;
}

.links a {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    var(--dark-gray-color),
    transparent 8%,
    transparent 80%,
    var(--dark-gray-color)
  );
  pointer-events: none;
}
.overlay-right {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    transparent 80%,
    var(--dark-gray-color)
  );
  pointer-events: none;
}
